import React from "react";
import CarItem from "./CarItem";
import { carsPull } from "../../shared/constants";
import CarParams from "./CarParams";
import { useDispatch } from "react-redux";
import { addCar, defaultState } from "../../Redux/slices/carSlice";
import { Link, useParams } from "react-router-dom";

const CarBlock = () => {
  const [selectedCar, setSelectedCar] = React.useState(null);
  const dispatch = useDispatch();
  const handleCar = (car) => {
    setSelectedCar(car);
    dispatch(addCar(car));
  };

  return (
    <div className="carBlock--container">
      {carsPull.map((el) => (
        <div key={el.id + "1"} className="car--item">
          <div className="car--item__image" onClick={() => handleCar(el)}>
            <Link to={`/autopark/${el.link}`}>
              <img src={el.src} alt="car" />
            </Link>
          </div>
          <div className="car--item__discrpion">
            <h5>{el.discr}</h5>
            <p>Цена {el.price}֏</p>
          </div>
          <CarParams props={el} />
        </div>
      ))}
    </div>
  );
};

export default CarBlock;

import React from "react";
import clockIcon from "../assets/layouts/icons/clock.svg";
import { getHours } from "../shared/functions";
export const CustomDate = (props) => {
  const { handleInputChange, name } = props;
  const dateNow = new Date();
  const hours = dateNow.getHours();
  const [selectedDate, setSelectedDate] = React.useState(hours);
  const [openTime, setOpenTime] = React.useState(false)
  const hoursValue = getHours();
  const propFunction = (el) => {
    setSelectedDate(el.value);
    handleInputChange({
      name,
      value: el.value,
    });
    setOpenTime(!openTime)
  };
  return (
    <div className="custom_date-container">
      <div className="custom_date-wrapper" onClick={() => setOpenTime(!openTime)}>
        <p className="custom_date-text">{selectedDate}</p>
        <img src={clockIcon} alt="clock" />
      </div>
      <div className={openTime ? "custom_date-date_active" : "custom_date-date"}>
        {hoursValue.map((el) => (
          <p
            className="custom_date-text"
            key={el.id}
            onClick={() => propFunction(el)}
          >
            {el.value}
          </p>
        ))}
      </div>
    </div>
  );
};

import React from "react";
import keysImg from '../assets/images/keys.webp'
import addServices from '../assets/images/additional__services.jpeg'
const Conditions = () => {
  return (
    <section className="conditions--container">
      <div className="conditions--wrapper"></div>
      <div className="conditions--container__text">
        <p>Условия и Услуги</p>
      </div>
      <div className="conditions--container_wrapper">
        <h3>Условия к арендатору</h3>
        <div className='conditions--top'>
          <div className='conditions--top__left'>
            <ul className="rental-conditions">
              <li>Возраст от 21 года, стаж вождения от 2х лет. </li>
              <li>
                Необходимо предъявить действующее водительское удостоверение и
                паспорт. Международные водительские права могут потребоваться если
                национальное ВУ не на латиннице.
              </li>
              <li>
                Для граждан Армении, России, Казахстана, Беларуси и Кыргызстана: внутренний
                или заграничный паспорт
              </li>
              <li>Для граждан других стран необходим заграничный паспорт</li>
              <li>
                Забронировать автомобиль офлайн в Офисе Reliant Ride Rental Cars
                (ул.Фанарджян д. 35) Вы можете в рабочие часы с 10-00 до 20-00
                бесплатно и без предоплат.
              </li>
              <li>
                Вы так же можете забронировать автомобиль по телефону, в чате на
                сайте, мессенджерам: whatsapp, telegram либо через форму
                бронирования на сайте.
              </li>
              <li>
                Все автомобили сдаются в аренду чистыми и с полным баком топлива
                (заряда).
              </li>
              <li>
                Все автомобили оборудованы зарядными и удерживающими устройствами
                для ваших телефонов.
              </li>
              <li>
                Наши автомобили сдаются без ограничения пробега по всей территории
                Армении.
              </li>
              <li>
                Для заключения договора Аренды требуется депозит в размере 100000
                драм или эквивалентно в других валютах.{" "}
              </li>
              <li>
                Дополнительный водитель. Вы можете добавить одного или нескольких
                дополнительных водителей.{" "}
              </li>
            </ul>
          </div>
          <div className='conditions--top__right'>
            <img src={keysImg} alt=""/>
          </div>
        </div>
        <h3>Дополнительные платные услуги</h3>
        <div className='conditions--bottom'>
          <div className='conditions--bottom__left'>
            <img src={addServices} alt=""/>
          </div>
          <div className='conditions--bottom__right'>
            <p>
              Дорогие Клиенты, данные услуги не обязательны, но могут помочь Вам
              повысить комфорт в период аредны автомобиля!
            </p>
            <ul>
              <li>
                Вы можете воспользоваться доставкой автомобиля в аэропорт Звартноц,
                а также по любому указанному Вами адресу. Стоимость 4000 драм.
              </li>
              <li>
                Вы можете оформить разрешение на выезд в Грузию. На территории
                Грузии пробег не ограничен. Стоимость 15000 драм.{" "}
              </li>
              <li>
                Вы можете воспользоваться страховкой с полным покрытием Стоимость
                8000 драм/сутки. С увеличением продолжительности аренды
                предоставляется привлекательная скидка.
              </li>
              <li>
                Вы можете заказать Детское кресло или коляску для детей любого
                возраста в любой момент в течении срока аренды Стоимость 4000
                драм/сутки, но не более 20000 драм за период аренды.
              </li>
              <li>
                Вы можете заказать WiFi роутер, оставаясь всегда на связи, в любой
                момент в течении срока аренды Стоимость 2000 драм/сутки, но не более
                10000 драм за период аренды
              </li>
              <li>
                Вы можете отказаться от обязательной мойки автомобиля по окончанию
                срока аренды Стоимость 4000 драм.
              </li>
              <li>
                Вы можете отказаться от обязательной заправки автомобиля по
                окончанию срока аренды Стоимость 4000 драм+ компенсация недостающего
                количества топлива из расчета 600 драм/литр.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="conditions--slide--bg"></div>
    </section>
  );
};

export default Conditions;

import React from 'react'
import { Link } from 'react-router-dom'

const BlogPost = ({data}) => {
  const {id, head} = data
  const {image, theme, title, description} = head
  return (
    <Link className='post-link' to={`/blog/${id}`}>
        <div className='post-image'>
            <img src={image} alt="" />
        </div>
        <h3 className='post-title'>{theme}</h3>
        <div className='post-text'>
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    </Link>
  )
}

export default BlogPost
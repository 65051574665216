import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    person: {},
    options: [],
    finalPrice: 0,
    carPrice: 0,
    optionPrice: 0,
    starOptionPrice: 0
}

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        changeForm(state, action) {
            state.person[action.payload.name] = action.payload.value
        },
        createFinalPrice(state, action) {
            state.finalPrice = action.payload
        }
    }
})
export const formSliceData = (state) => state.form

export const { changeForm, createFinalPrice } = formSlice.actions

export default formSlice.reducer
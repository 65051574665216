import React from 'react'

const Security = () => {
  return (
    <section className="security--container">
    <div className="security--wrapper"></div>
    <div className="security--container__text">
      <p>Безопасность и Комфорт — наш приоритет.</p>
    </div>
    <div className="security--container_wrapper">
          <ul className="rental-security">
            <li className='li-title'>Безопасность движения</li>
            <li>
            Все автомобили нашего парка поддерживают высокие стандарты безопасности. Регулярные технические проверки и обслуживание обеспечивают надежность транспортных средств.
            </li>
            <li className='li-title'>Прозрачность и отсутствие навязанных услуг</li>
           
            <li>
            Мы ценим ваше время и деньги. Наши цены честные и прозрачные, без навязанных услуг или скрытых платежей. Вы получаете то что желаете.
            </li>
            <li className='li-title'>Объективная оценка повреждений</li>
            <li>
            При выдаче и возврате автомобиля нашей командой проводится тщательная оценка повреждений и недостатков. Мы не скрываем тех недостатков, о которых нам известно и всегда о них расскажем при выдаче авто. Мы стремимся быть справедливыми и объективными, и не взымаем дополнительных плат за «каждый скол»
            </li>
            <li className='li-title'>
            Техническая поддержка 24/7
            </li>
            <li>
            Мы ценим наших клиентов и предлагаем постоянную связь с нашими менеджерами для решения любых вопросов и обеспечения вашего комфорта. Наша техническая поддержка доступна 24/7, готовая помочь в случае любых проблем или вопросов.
            </li>
          </ul>
          <p>
          Выбирая нас, вы выбираете не только автомобиль, но и партнера, заботящегося о вашей безопасности и комфорте на дороге. Доверьтесь нам, и ваше путешествие станет незабываемым и беззаботным!

          </p>
    </div>
    <div className="security--slide--bg"></div>
  </section>
  )
}

export default Security
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedCar } from "../Redux/slices/carSlice";
import { formSliceData } from "../Redux/slices/formSlice";
import { changeForm } from "../Redux/slices/formSlice";
import { useNavigate } from "react-router-dom";
import { CustomDate } from "./CustomDate";
import { getCurrentCar } from "../shared/functions";
import { useParams } from "react-router-dom";
import { simpleEncrypt } from "../shared/functions";
import { encryptPromocodes } from "../shared/constants";
import { defaultState } from "../Redux/slices/carSlice";
const Constructor = (props) => {
  const navigate = useNavigate();
  const { model } = useParams();
  const { selectedOptions, optionPrice, starOptionPrice, discountCode } =
    useSelector(selectedCar);
  const { person } = useSelector(formSliceData);
  const car = getCurrentCar(model);
  const [age, setAge] = React.useState(false);
  // const {finalPrice} = useSelector(formSliceData);
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState({
    name: "",
    phone: "",
    placeFrom: "",
    placeTo: "",
    dateFrom: "",
    dateTo: "",
    timeFrom: "",
    timeTo: "",
  });

  React.useEffect(() => {
    const currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    var day = currentDate.getDate().toString().padStart(2, "0");
    var formattedDateFrom = year + "-" + month + "-" + day;
    var formattedDateTo =
      year +
      "-" +
      month +
      "-" +
      (parseInt(day) + 1).toString().padStart(2, "0");
    var hours = currentDate.getHours().toString().padStart(2, "0");
    var formattedTime = hours;
    setFormData({
      ...formData,
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
      timeFrom: formattedTime,
      timeTo: formattedTime,
    });
    dispatch(changeForm({ name: "dateFrom", value: formattedDateFrom }));
    dispatch(changeForm({ name: "dateTo", value: formattedDateTo }));
    dispatch(changeForm({ name: "timeFrom", value: formattedTime }));
    dispatch(changeForm({ name: "timeTo", value: formattedTime }));
  }, []);
  const handleInputChange = (data) => {
    dispatch(changeForm(data));
    setFormData({
      ...formData,
      [data.name]: data.value,
    });
  };
  const calculateDays = () => {
    const decodedKey = process.env.REACT_APP_SECRET_KEY;
    const { placeFrom, placeTo, dateFrom, timeFrom, dateTo, timeTo } = person;
    const carPrice = car.price;
    const promoDiscount =
      encryptPromocodes[simpleEncrypt(discountCode, decodedKey)];
    let extraDiscount = promoDiscount ? 1 - promoDiscount / 100 : 1;
    const startDate = new Date(`${dateFrom}T${timeFrom}:00`);
    const endDate = new Date(`${dateTo}T${timeTo}:00`);
    const timeDifference = endDate - startDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    const oprionPrice =
      daysDifference < 6
        ? optionPrice + starOptionPrice * daysDifference
        : optionPrice + starOptionPrice * 5;
    if (daysDifference < 0) {
      throw new Error("Не верная дата");
    } else {
      let firstTax = 0,
        finishPrice = 0,
        lastTax = 0;
      if (placeFrom === "г. Ереван, ул. Фанаржян, д. 35.") {
        firstTax = 0;
      } else {
        firstTax = 4000;
      }
      if (placeTo === "г. Ереван, ул. Фанаржян, д. 35.") {
        lastTax = 0;
      } else {
        lastTax = 4000;
      }
      let taxes = firstTax + lastTax;
      if (daysDifference > 3 && daysDifference <= 7) {
        finishPrice =
          Math.floor(
            ((carPrice * 0.95 * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      } else if (daysDifference > 7 && daysDifference <= 14) {
        finishPrice =
          Math.floor(
            ((carPrice * 0.9 * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      } else if (daysDifference > 14 && daysDifference <= 30) {
        finishPrice =
          Math.floor(
            ((carPrice * 0.85 * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      } else if (daysDifference > 30) {
        finishPrice =
          Math.floor(
            ((carPrice * 0.8 * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      } else {
        finishPrice =
          Math.floor(
            ((carPrice * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      }
      return finishPrice;
    }
  };
  const handlerFormSumbit = async (e) => {
    try {
      const total = calculateDays();
      const chatId = process.env.REACT_APP_CHAT_ID;
      const myToken = process.env.REACT_APP_TOKEN_ID;
      const options = selectedOptions.map((el) => el.name).join(", ");
      const message = `Авто: ${props.car};
    Имя: ${formData.name}, Телефон: ${formData.phone}
    Место откуда: ${formData.placeFrom};
    Дата: ${formData.dateFrom}; Время: ${formData.timeFrom};
    Место куда: ${formData.placeTo};
    Дата: ${formData.dateTo}; Время: ${formData.timeTo}
    Опции: ${options}.
    Стоимость: ${total}`;
      const response = await fetch(
        `https://api.telegram.org/bot${myToken}/sendMessage?chat_id=${chatId}&text=${message}`
      );
      if (response.ok) {
        e.preventDefault();
        navigate("/riding");
        alert("Заявка отправлена");
        dispatch(defaultState())
      }
    } catch (error) {
      alert(error.message);
    }
  };
  return (
    <div id="form" className="constructor--container">
      <div className="constructor--container_wrapper">
        <form
          onSubmit={handlerFormSumbit}
          action=""
          method="get"
          className="constructor--container_wrapper-form"
        >
          <div className="constructor-example">
            <label htmlFor="name">1. ФИО</label>
            <input
              onChange={(e) =>
                handleInputChange({ name: "name", value: e.target.value })
              }
              value={formData.name}
              type="text"
              name="name"
              id="name"
              placeholder="Имя"
              required
            />
            <label htmlFor="phone">2. Номер телефона</label>
            <input
              onChange={(e) =>
                handleInputChange({ name: "phone", value: e.target.value })
              }
              value={formData.phone}
              type="number"
              id="phone"
              placeholder="Телефон"
              name="phone"
              required
            />
          </div>
          <div className="constructor-example">
            <label htmlFor="birth">3. Выберите место и дату приема авто</label>
            <input
              onChange={(e) =>
                handleInputChange({ name: "placeFrom", value: e.target.value })
              }
              value={formData.placeFrom}
              type="text"
              placeholder="Город, улица, дом"
              name="placeFrom"
              id="birth"
              required
            />
            <div className="constructor-example_location">
              <span
                onClick={() =>
                  handleInputChange({
                    name: "placeFrom",
                    value: "г. Ереван, ул. Фанаржян, д. 35.",
                  })
                }
              >
                Офис
              </span>
              <span
                onClick={() =>
                  handleInputChange({
                    name: "placeFrom",
                    value: "Международный аэропорт Ереван Звартноц",
                  })
                }
              >
                Аэропорт
              </span>
            </div>
          </div>
          <div className="constructor-example">
            <div className="constructor-example_date">
              <input
                onChange={(e) =>
                  handleInputChange({
                    name: "dateFrom",
                    value: e.target.value,
                  })
                }
                value={formData.dateFrom}
                type="date"
                name="date"
                required
              />
              <CustomDate
                handleInputChange={handleInputChange}
                name="timeFrom"
              />
              {/* <input
                onChange={(e) =>
                  handleInputChange({ name: "timeFrom", value: e.target.value })
                }
                value={formData.timeFrom}
                type="time"
                name="time"
                step="3600"
                required
              /> */}
            </div>
          </div>
          <div className="constructor-example">
            <label htmlFor="placeTo">4. Выберите место и дату сдачи авто</label>
            <input
              onChange={(e) =>
                handleInputChange({ name: "placeTo", value: e.target.value })
              }
              value={formData.placeTo}
              type="text"
              id="placeTo"
              placeholder="Город, улица, дом"
              name="placeTo"
              required
            />
            <div className="constructor-example_location">
              <span
                onClick={() =>
                  handleInputChange({
                    name: "placeTo",
                    value: "г. Ереван, ул. Фанаржян, д. 35.",
                  })
                }
              >
                Офис
              </span>
              <span
                onClick={() =>
                  handleInputChange({
                    name: "placeTo",
                    value: "Международный аэропорт Ереван Звартноц",
                  })
                }
              >
                Аэропорт
              </span>
            </div>
          </div>
          <div className="constructor-example">
            <div className="constructor-example_date">
              <input
                onChange={(e) =>
                  handleInputChange({ name: "dateTo", value: e.target.value })
                }
                value={formData.dateTo}
                type="date"
                name="dateTo"
                required
              />
              <CustomDate handleInputChange={handleInputChange} name="timeTo" />
              {/* <input
                onChange={(e) =>
                  handleInputChange({ name: "timeTo", value: e.target.value })
                }
                value={formData.timeTo}
                type="time"
                name="timeTo"
                step="3600"
                required
              />*/}
            </div>
          </div>
          <div className="terms_container">
            <div className="terms_wrapper">
              <div className="terms">
                <input
                  type="checkbox"
                  checked={age}
                  onChange={(e) => setAge(e.target.checked)}
                />
                <p>Возраст водителя от 21 года, стаж от 2-х лет</p>
              </div>
            </div>
            <div className="constructor-example">
              <input
                disabled={age ? false : true}
                className="submit"
                type="submit"
                value="Забронировать"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Constructor;

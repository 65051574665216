import { keyboard } from '@testing-library/user-event/dist/keyboard'
import { first_post, second_post, third_post, fourth_post, fifth_post, sixth_post, seventh_post, eighth_post, nineth_post, tenth_post, eleventh_post, twelfth_post } from '../assets/images/blog/index'
export const posts = [
    {
        id: 1,
        head: {
            image: first_post,
            theme: 'Движение',
            title: 'Особенности дорожного движения в республике Армения.',
            description: 'Граждане каких стран могут управлять автотранспортными средствами в республике Армения?'
        },
        body: {
            title: 'Особенности дорожного движения в республике Армения.',
            article: {
                title: 'Контент',
                topics: [
                    { id: 1000, text: 'Максимальная скорость движения', },
                    { id: 1001, text: 'Камеры в Армении', },
                    { id: 1002, text: 'Система платных парковок', },
                    { id: 1003, text: 'Курение и алкоголь', },
                    { id: 1004, text: 'Дети до 12 лет', },
                    { id: 1005, text: 'звуковой сигнал', },
                    { id: 1006, text: 'предусмотрены штрафы' },
                ]
            },
            img: first_post,
            topics: [
                {
                    id: 10,
                    title: 'Армения',
                    descriptions: [
                        {
                            id: 101,
                            keyword: 'Страна, которая подписала венскую конвенцию о дорожном движении от 8 ноября 1968 года.',
                            main: `Венская конвенция о дорожном движении - это договор между странами о стандартизации правил дорожного движения с целью повышения безопасности. 
                            Страны, которые подписали данную конвенцию, признают национальные водительские удостоверения друг друга, без необходимости получать международные удостоверения. 
                            Также конвенцией о дорожном движении определен формат номерных знаков транспортных средств. В номерах автомобилей стран-участниц конвенции могут использоваться только арабские цифры и буквы латиницы или буквы других алфавитов, которые им аналогичны. 
                            Иностранец, приехавший в Армению из страны-участницы конвенции  и имеющий водительское удостоверение, может спокойно управлять автомобилем. 
                            Гражданин США или Канады или любой другой страны, которая не подписывала данную конвенцию, также может управлять автомобилем, при наличии водительского удостоверения и при условии что оно написано латинскими буквами. Правила дорожного движения Армении практически не отличаются от ПДД в России, но вот характер и темперамент вождения совершенно другой.`,
                        }
                    ]
                },
                {
                    id: 11,
                    title: 'Далее мы перечислим несколько особенностей дорожного движения, которые помогут вам безопасно и комфортно управлять автомобилем:',
                    descriptions: [
                        {
                            id: 111,
                            keyword: 'Максимальная скорость',
                            main: ' в пределах населенных пунктов, поселков, деревнях – 60 км/ч, за пределами населенных пунктов, городов, поселков, деревень – 90 км/ч, на скоростных трассах Армении 110 км/ч допустимое превышение скорости – 10 км/ч, однако мы советуем придерживаться указанного знаками ограничения скоростного режима.',
                        },
                        {
                            id: 112,
                            keyword: 'В Армении очень много камер,',
                            main: ' следящих за соблюдением правил дорожного движения: превышение скоростных ограничений, пересечение стоп линий, проезд по выделенным полосам движения общественного транспорта, пересечение сплошных разделительных линий в т.ч. разделяющих попутные полосы движения.',
                        },
                        {
                            id: 113,
                            keyword: 'В Ереване действует',
                            main: ' система платных автостоянок, а также взыскания штрафов за неуплату за парковку. Территории автостоянок на улицах города помечаются красными линиями, обычно это центр города.',
                            link: ' Официальный сайт и карта платных парковок.',
                            href: 'https://www.pcs.am/map/'
                        }, {
                            id: 114,
                            keyword: 'Не смотря на то,',
                            main: ' что в Армении очень много курят и делают это везде, правилами дорожного движения запрещено курить за рулем, за это предусмотрена административная ответственность. Алкоголь за рулем строго запрещен. Допустимый уровень алкоголя в крови составляет 0,0%.',
                        }
                        , {
                            id: 115,
                            keyword: 'Дети до 12 лет',
                            main: ' должны сидеть на заднем ряду сидений. Также необходимо использовать автокресло, соответствующее возрасту ребёнка.',
                        }
                        , {
                            id: 116,
                            keyword: 'Если отступить от ПДД,',
                            main: ' то хочется отметить, как часто водители Армении используют звуковой сигнал автомобиля(клаксон,гудок и пр.). Звуковой сигнал может значить негодование, злость, приветствие или то,что водитель уступает вам дорогу.',
                        },
                        {
                            id: 117,
                            keyword: 'За нарушение правил дорожного движения',
                            main: 'предусмотрены штрафы. С размером штрафов Вы можете ознакомиться по ссылке ',
                            link: ' https://haywiki.org/drive/fines.html ',
                            href: 'https://haywiki.org/drive/fines.html',
                            end: 'В заключение хочется добавить, что в целом правила дорожного движения стандартные и не имеют ярковыраженных отличий от правил в других странах. Для управления автомобилем в Республике Армения достаточно просто уметь водить, быть внимательным и осторожным.'
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 2,
        head: {
            image: second_post,
            theme: 'Факты',
            title: 'Интересные Факты об Армении.',
            description: 'Сегодня поговорим о 5 нетипичных фактах о республике Армения. Спорим ты не знал что:'
        },
        body: {
            title: 'Интересные Факты об Армении.',
            article: {
                title: ' Контент',
                topics: [
                    { id: 2000, text: 'древний город' },
                    { id: 2001, text: 'Армянский алфавит' },
                    { id: 2002, text: 'страна кофе' },
                    { id: 2003, text: 'армянская кухня' },
                    { id: 2004, text: 'смерть от жажды' },
                ]
            },
            img: second_post,
            topics: [
                {
                    id: 20,
                    title: 'Сегодня поговорим',
                    descriptions: [
                        {
                            id: 210,
                            keyword: '5 нетипичных фактов ',
                            main: 'о республике Армения. ',
                        }
                    ]
                },
                {
                    id: 21,
                    title: 'Спорим ты не знал что:',
                    descriptions: [
                        {
                            id: 211,
                            keyword: 'Ереван - столица',
                            main: ' Армении самый древний город в мире. Эребуни древний город государства Урарту, развалины которого расположены на холме Арин - Берд в Ереване, был основан в 782 году до нашей эры. Таким образом Ереван на 29 лет старше Рима.',
                        },
                        {
                            id: 212,
                            keyword: 'Армянский алфавит',
                            main: ' - не только очень красив, но и загадочен. Если взять  слово Золото (по Армянски Воски) и сложить порядковые номера букв, согласно армянскому алфавиту, то получится 79, что соответствует порядковому номеру этого элемента в таблице Д.И. Менделеева. Это же загадочное совпадение обнаружилось у таких элементов как: серебро, свинец и ртуть.',
                        },
                        {
                            id: 213,
                            keyword: 'Армения - страна кофе.',
                            main: ' Здесь все очень любят кофе и пьют его в больших количествах. Все варят кофе в турках или кофеварках, а здесь кофе варят кастрюлями, чтобы угостить сразу всех многочисленных гостей. Практически в каждом магазине есть уголок, где можно приобрести разные виды кофе в зернах, а также имеется кофемолка.                            ',
                        }, {
                            id: 214,
                            keyword: 'В армянской кухне',
                            main: ' используется более 300 видов зелени. В одном из самых необычных и вкусных национальных блюд Жянгялов хац используется 25 видов зелени.',
                        }
                        , {
                            id: 215,
                            keyword: 'Армения страна,',
                            main: ' где невозможно умереть от жажды. Пульпулак - общественный питьевой фонтан очень распространен на территории всей страны. В одном только Ереване - около 1500 фонтанчиков. Как правило они устанавливаются в честь какого то события или человека. Многие фонтаны отключают на зиму, но есть некоторые, из которых можно попить даже в самое холодное время года.',
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 3,
        head: {
            image: third_post,
            theme: 'Поездки зимой',
            title: 'Топ-5 мест для поездок зимой в Армении.',
            description: 'Не секрет, что зимой многие люди предпочитают активный вид отдыха: горные лыжи, сноуборд, коньки и прочее. Но что же делать любителям спокойного и размеренного отдыха? Вот несколько  мест для любителей насладиться тишиной и спокойствием. '
        },
        body: {
            title: 'Топ-5 мест для поездок зимой в Армении.',
            article: {
                title: 'Контент',
                topics: [
                    { id: 3000, text: 'Джермук', },
                    { id: 3001, text: 'Анкаван', },
                    { id: 3002, text: 'Дилижан', },
                    { id: 3003, text: 'Храм Гарни', },
                    { id: 3004, text: 'Дети до 12 лет', },
                    { id: 3005, text: 'Симфония камней', },
                    { id: 3006, text: 'предусмотрены штрафы' },
                ],
            },
            img: third_post,
            topics: [
                {
                    id: 31,
                    title: 'Топ-5 мест для поездок зимой в Армении.',
                    descriptions: [
                        {
                            id: 310,
                            main: 'Не секрет, что зимой многие люди предпочитают активный вид отдыха: горные лыжи, сноуборд, коньки и прочее. Но что же делать любителям спокойного и размеренного отдыха? Вот несколько  мест для любителей насладиться тишиной и спокойствием.'
                        },
                        {
                            id: 311,
                            keyword: 'Джермук. ',
                            main: 'В 170 км от Еревана находится высокогорный курорт с десятками источников пресной и минеральной  воды. Здесь можно прокатиться на канатной дороге, а также посетить питьевую галерею и насладиться разными видами минеральной воды.',
                        },
                        {
                            id: 312,
                            keyword: 'Анкаван. ',
                            main: 'Поселок в 85 километрах от Еревана в долине реки Мармарик. Здесь находится одноименный курорт Анкаван на высоте 1900 метров над уровнем моря. Здесь можно искупаться в термальных минеральных источниках (температура 40-42 градуса C), которые считаются очень полезными для организма.',
                        },
                        {
                            id: 313,
                            keyword: 'Дилижан. ',
                            main: 'Небольшой город на севере Армении примерно в 100 км от Еревана. Здесь можно отдохнуть от городской суеты, насладиться прекрасным горным пейзажем и подышать ароматным хвойным воздухом.',
                        }, {
                            id: 314,
                            keyword: 'Храм Гарни. ',
                            main: 'Всего в 28 км от Еревана расположен древний языческий  храм, посвященный богу солнца Митре. Он был построен в 1 веке нашей эры армянским царем Трдатом I. Храм Гарни — единственный сохранившийся на территории Армении памятник, относящийся к дохристианской эпохе и временам эллинизма.',
                        }
                        , {
                            id: 315,
                            keyword: 'Рядом с языческим',
                            main: ' храмом Гарни, расположена еще одна достопримечательность, которую по истине можно считать одним из чудес света. Симфония камней - это базальтовые скалы в форме прямых труб , высотой около 100 метров. Свое название симфония камней получила из-за внешнего сходства с музыкальным инструментом - органом. Образование таких скал - это результат резкого остывания и кристаллизации вулканической лавы.',
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 4,
        head: {
            image: fourth_post,
            theme: 'Армянский язык',
            title: 'Армянский язык великий и прекрасный!',
            description: 'Особенности армянского языка. Интересные факты. Отличия армянского языка от русского.'
        },
        body: {
            title: 'Армянский язык великий и прекрасный!',
            article: {
                title: 'Контент',
                topics: [
                    { id: 4000, text: 'Армянский алфавит', },
                    { id: 4001, text: 'Вид алфавита', },
                    { id: 4002, text: 'памятник алфавиту', },
                    { id: 4003, text: 'согласные - аффрикаты', },
                    { id: 4004, text: 'склонения по родам', },
                    { id: 4005, text: 'Знаки препинания', },
                    { id: 4006, text: 'формы языка' },
                    { id: 4007, text: 'диалекты' },
                    { id: 4008, text: 'времена' },
                ],
            },
            img: fourth_post,
            topics: [
                {
                    id: 41,
                    title: 'Особенности армянского языка.',
                    descriptions: [
                        {
                            id: 411,
                            keyword: 'Армянский алфавит',
                            main: ' был создан Месропом Маштоцем в 1-й половине V века. Изначально в алфавите было 36 букв: 7 гласных и 29 согласных, но затем в XII веке добавили еще три буквы. Сейчас в современном варианте алфавита присут 39 букв: 9 гласных и 30 согласных.                             ',
                        },
                        {
                            id: 412,
                            keyword: 'Внешний вид',
                            main: ' армянского алфавита уникален и не похож ни на один из алфавитов. Столкнувшись с надписью на армянском языке, вы точно удивитесь, потому что ни одна из букв не соответствует привычным нашему глазу символам латиницы или кириллицы. Более того, написание печатных и рукописных букв также отличается.                            ',
                        },
                        {
                            id: 413,
                            keyword: 'Армяне настолько',
                            main: ' гордятся своим языком, что существует даже памятник алфавиту. Он находится на западном склоне горы Арагац в селе Арташаван. Памятник представляет собой 39 скульптур - букв армянского алфавита, которые сделаны из армянского туфа. Памятник был построен в 2005 году в честь 1600-лет создания армянской письменности.                             ',
                        }, {
                            id: 414,
                            keyword: 'В армянском языке',
                            main: ' существуют сложнопроизносимые согласные - аффрикаты, аналогов которым нет в русском языке. Они передают звук сразу двух букв, например: тх; тч; пх; дж; кх; дз; тс. ',
                        }
                        , {
                            id: 415,
                            keyword: 'В армянском языке',
                            main: ' нет привычного русскому человеку склонения по родам. Но есть один суффикс, которые ставится в конце некоторых слов и образует феминитивы.',
                        }
                        , {
                            id: 416,
                            keyword: 'Знаки препинания',
                            main: ' в армянском языке также отличаются. Например в конце предложения вместо точки ставится двоеточие. А вопросительного знака в привычном русскому человеку виде и вовсе не существует. Для того чтобы обозначить вопрос в предложении используется специальный символ «՞», и ставится он над последней гласной слова, в котором содержится вопрос.',
                        },
                        {
                            id: 417,
                            keyword: 'Существует две',
                            main: ' формы армянского языка: западно-армянская и восточно-армянская. В Армении разговаривают на восточно-армянском варианте языка. Он же является официальным языком республики Армения. Диаспоры, проживающие на ближнем востоке, странах Европы и в Америке используют для общения западно-армянскую форму языка.'
                        },
                        {
                            id: 418,
                            keyword: 'У армянского языка',
                            main: ' очень много диалектов. Зачастую носители разных языковых диалектов при общении не понимают друг друга, из-за существенной разницы в словах и их значениях. Для общения между собой носители разных диалектов могут использовать третий язык, например русский.',
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 5,
        head: {
            image: fifth_post,
            theme: 'Бренды',
            title: 'Бренды в Армении.',
            description: 'Один из самых популярных вопросов, который можно услышать от русскоязычных туристов или релокантов, это есть ли в Армении Макдональдс, Икеа, и H&M. Далее рассмотрим какие мировые бренды представлены в Армении и где их найти.'
        },
        body: {
            title: 'Бренды в Армении.',
            article: {
                title: 'Контент',
                topics: [
                    { id: 5000, text: 'Есть идея есть IKEA' },
                    { id: 5001, text: 'магазин H&M' },
                    { id: 5002, text: 'официальные магазины брендов' },
                ]
            },
            img: fifth_post,
            topics: [
                {
                    id: 50,
                    title: 'Один из самых популярных вопросов',
                    descriptions: [
                        {
                            id: 501,
                            keyword: 'Есть ли в Армении Макдональдс, Икеа, и H&M. ',
                            main: 'После ухода Macdonalds из России, многие люди ищут рестораны этой сети в Армении. Но ближайший ресторан Macdonalds находится в Тбилисси на территории республики Грузия. Итог - в Армении нет Macdonalds и более того ничего не слышно о планах по их открытию. Почему? Никто не знает, но есть предположения, что при всем многообразии и великолепии армянской кухни, он вряд ли бы пользовался большим спросом. А для любителей Американской кухни спешим добавить, что здесь есть рестораны известных сетей KFC и бургер Кинг.',
                        }
                    ]
                },
                {
                    id: 51,
                    title: 'Далее рассмотрим какие мировые бренды представлены в Армении и где их найти.',
                    descriptions: [
                        {
                            id: 511,
                            keyword: 'Есть идея есть IKEA -',
                            main: ' гласит известный слоган не менее известной компании. Но если из России IKEA ушла, то в Армению она еще и не приходила. В Ереване на улице Айрика Мурадяна дом 20 есть магазин мебели с гордым названием IKEA Armenia, но он не является официальным представительством бренда. В данном магазине представлен некоторый ассортимент товаров из IKEA.',
                        },
                        {
                            id: 512,
                            keyword: 'Официально магазин H&M',
                            main: ' в Армении не представлен. По аналогии с магазином IKEA, в Ереване на улице Комитаса есть магазин, на окнах которого красуется баннер H&M. Там также представлены некоторые товары бренда H&M.',
                        },
                        {
                            id: 513,
                            keyword: 'С остальными известными брендами',
                            main: ' в Армении получше. Есть официальные магазины таких брендов как: Zara, Bershka, Pull&Bear, Massimo duti, Stradivarius, United colors of Benetton, Adidas, Reebok, Converse и многое другое. Чтобы посетить их в Ереване есть достаточное количество отличных современных торговых центров, на сайте которых всегда можно уточнить наличие или отсутствие конкурентно магазина. Но лично наш фаворит среди всех торговых центров это Dalma garden mall - он пожалуй самый большой, а также в нем есть все вышеперечисленные магазины.',
                        },
                    ]
                },
            ]
        }
    },
    {
        id: 6,
        head: {
            image: sixth_post,
            theme: 'Торговые центры',
            title: 'Топ-3 торговых центра в Ереване.',
            description: 'Купить новое платье, посмотреть премьеру в кино, поесть на фуд-корте, посетить мастер класс, а заодно купить продукты домой- все это можно сделать в одном месте. Речь конечно о торговых центрах и сегодня мы поговорим о самых крупных торговых центрах в Ереване.',
        },
        body: {
            title: 'Топ-3 торговых центра в Ереване.',
            article: {
                title: 'Контент',
                topics: [
                    { id: 6000, text: 'Dalma garden mall' },
                    { id: 6001, text: 'РИО Молл' },
                    { id: 6002, text: 'Мега Молл Армения' },
                ]
            },
            img: sixth_post,
            topics: [
                {
                    id: 60,
                    title: 'Торговые центры',
                    descriptions: [
                        {
                            id: 601,
                            keyword: 'Купить новое платье,',
                            main: ' посмотреть премьеру в кино, поесть на фуд-корте, посетить мастер класс, а заодно купить продукты домой- все это можно сделать в одном месте.',
                        }
                    ]
                },
                {
                    id: 61,
                    title: 'Поговорим о самых крупных торговых центрах в Ереване',
                    descriptions: [
                        {
                            id: 611,
                            keyword: 'Dalma garden mall -',
                            main: ' Цицернакабердское шоссе, дом 3. Просторный современный красивый 3-х этажный торговый центр. На прилегающей территории имеется открытая бесплатная парковка для автомобилей и велосипедов. В самом торговом центре имеется множество известных брендовых магазинов, таких как: Zara, Bershka, Massimo duti, Adidas, Converse, Levis, LC Waikiki и многое другое. Помимо магазинов, здесь есть кинотеатр, фуд-корт, детская зона развлечений и детская парикмахерская. Здесь есть услуга аренды детских машинок и колясок для комфортного передвижения вашего малыша по территории ТЦ. На цокольном этаже есть большой продуктовый магазин Erevan city и аптека. Торговый центр оснащен эскалаторами , а также лифтом - что невероятно удобно для посетителей с детьми и маломобильных людей. А еще в ясную погоду здесь открывается шикарный вид на Арарат.',
                        },
                        {
                            id: 612,
                            keyword: 'РИО Молл -',
                            main: ' улица Ваграма Папазяна, дом 8. Достаточно большой по площади и довольно скромный по наполнению торговый центр в одном из самых крупных и благополучных районов Еревана - Арабкире. Фишка этого торгового центра - это наличие небольшого контактного зоопарка. Что касается магазинов, здесь есть такие бренды как: Lime, U.S. Polo Assn, Acoola, Play today, Love republic, be free, ТВОЕ, и др.  В данном торговом центре также есть кинотеатр, большая детская зона развлечений и фуд- корт. Неподалеку от фуд-корта имеется пространство, где проводятся детские мастер-классы: лепка, рисование и прочее. Также в РИО есть большой продуктовый магазин Yerevan city и аптека. Бесплатная парковка  для автомобилей и велосипедов располагается на прилегающей территории.',
                        },
                        {
                            id: 613,
                            keyword: 'Мега Молл Армения - ',
                            main: 'проспект Гая, дом 16. Крупнейший многофункциональный торгово-развлекательный центр расположен в административном районе Нор-Норк. В Мега Моле 6 этажей, на трех подземных этажах из которых расположена парковка емкостью примерно на 1800 автомобилей. Также здесь находится фонтан, который занесен в книгу рекордов Гинесса. Что касается магазинов, то здесь представлены следующие бренды : Ostin, спортмастер, Lime, Marks&Spenser, Zippy, Geox и тд. Помимо магазинов, здесь есть кинотеатр, рестораны, кафе, фуд-корт, а также большая детская развлекательная зона. Также здесь есть пункт СДЭК и отделение банка Ameria, продуктовый магазин Yerevan city, аптека.',
                        },
                    ]
                },
            ]
        }
    },
    {
        id: 7,
        head: {
            image: seventh_post,
            theme: 'Выборы',
            title: 'Участие в президентских выборах России.',
            description: 'Как гражданам Российской Федерации, находящимся за границей принять участие в выборах в марте 2024 ?',
        },
        body: {
            title: 'Участие в президентских выборах России.',
            article: {
                title: 'Контент',
                topics: [
                    { id: 7000, text: 'Участие в выборах' },
                    { id: 7001, text: 'Подача заявления' },
                    { id: 7002, text: 'Информация для голосующих' },
                ]
            },
            img: seventh_post,
            topics: [
                {
                    id: 70,
                    title: 'Вы гражданин России',
                    descriptions: [
                        {
                            id: 701,
                            keyword: 'Живете в Армении',
                            main: ' и хотите участвовать в президентских выборах? Тогда этот пост для вас.',
                        }
                    ]
                },
                {
                    id: 71,
                    title: '',
                    descriptions: [
                        {
                            id: 711,
                            keyword: 'Гражданин Российской Федерации,',
                            main: ' даже находясь за границей имеет точно такое же право на участие в выборах, как и все остальные граждане РФ, находящиеся в пределах Российской Федерации.',
                        },
                        {
                            id: 712,
                            keyword: 'Для того чтобы принять участие',
                            main: ' в выборах не по месту регистрации согласно Вашему паспорту, а по месту нахождения необходимо уведомить Центральную избирательную комиссию (ЦИК) о своем намерении.',
                        },
                        {
                            id: 713,
                            keyword: 'Подать такое заявление',
                            link: 'через госуслуги',
                            href: 'https://gosuslugi.ru/600412/1/form',
                            main: 'о включении в список избирателей по месту нахождения в рамках избирательной кампании: Выборы Президента Российской Федерации можно ',
                        },
                        {
                            id: 714,
                            keyword: 'По данной ссылке откроется форма',
                            main: ' заявления, где вы заполняете свои данные, и выбираете участок, который Вы планируете посетить в день выборов. Далее, это заявление уходит в ЦИК. Как правило, на следующий день Вам приходит ответ, что Ваше заявление учтено ЦИК и контактные данные выбранного избирательного участка. К ответу прилагается распечатываемая часть заявления, которую обязательно нужно взять с собой на избирательный участок. Распечатываемую часть заявления допускается предъявлять как в бумажном виде так и на экране мобильного устройства.',
                        },
                        {
                            id: 715,
                            keyword: 'Также, по информации сайта',
                            main: ' госуслуг, в случае если Вы подали такое заявление, и затем Ваши планы изменились, то можно отозвать это заявление не позднее чем до 11 марта 2024.',
                        },
                        {
                            id: 716,
                            keyword: 'Обращаем Ваше внимание,',
                            main: 'что если Вы хотите принять участие в данном мероприятии, то не пренебрегайте подачей выше указанного заявления. Без подачи данного заявления Вашим избирательным участком будет считаться участок по месту Вашей регистрации, соответственно и бюллетень предназначенный Вам будет находиться там же и Вы не сможете оставить свой голос на другом участке.'
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 8,
        head: {
            image: eighth_post,
            theme: 'Гастрономия',
            title: 'Топ-5 блюд Армянской кухни, который должен попробовать каждый.',
            description: 'Армянскую кухню можно считать одной из главных достопримечательностей Армении.',
        },
        body: {
            title: 'Топ-5 блюд Армянской кухни, который должен попробовать каждый.',
            article: {
                title: 'Контент',
                topics: [
                    { id: 8000, text: 'Хаш' },
                    { id: 8001, text: 'хоровац' },
                    { id: 8002, text: 'Жянгялов хац' },
                    { id: 8003, text: 'Долма' },
                    { id: 8004, text: 'Хапама' },
                ]
            },
            img: eighth_post,
            topics: [
                {
                    id: 80,
                    title: 'Достопримечательности Армении',
                    descriptions: [
                        {
                            id: 801,
                            keyword: 'это не только горы, водопады и прочие невероятные природные красоты. ',
                            main: 'Армянскую кухню можно также считать одной из главных достопримечательностей Армении.',
                        }
                    ]
                },
                {
                    id: 81,
                    title: 'Гастрономические удовольствия',
                    descriptions: [
                        {
                            id: 811,
                            keyword: 'Если вы хотите познакомиться с армянской кухней,',
                            main: ' то рекомендуем обязательно попробовать эти блюда:',
                        },
                        {
                            id: 812,
                            keyword: '1.  Хаш -',
                            main: ' не просто национальное армянское блюдо, а также целый ритуал. По сути своей это горячий суп, который варится из говяжьих костей с добавлением рубца. Подают хаш со специальным хрустящим лавашем, а также с зеленью и овощами. Для любителей русской кухни можно сравнить хаш с холодцом. Только холодец он холодный и застывший, а хаш это жидкий и горячий бульон.',
                        },
                        {
                            id: 813,
                            keyword: '2.  Шашлык по-армянски называется хоровац.',
                            main: ' В Армении готовят самый лучший шашлык. Здесь люди с детства постигают это искусство приготовления мяса. Шашлык готовят на мангале или в тандыре. И в том и в другом варианте мясо получается очень вкусным. К шашлыку всегда подают лаваш и овощи. А если к этому добавить еще бокал вкуснейшего армянского вина, то вы точно получите массу удовольствия.',
                        },
                        {
                            id: 814,
                            keyword: '3.  Жянгялов хац -',
                            main: ' одно из самых необычных национальных блюд. Хац в переводе с армянского означает хлеб. По сути это лепешка с начинкой из мелко нарезанной зелени. Для приготовления этого блюда используется до 25 видов различных трав и специй.',
                        },
                        {
                            id: 815,
                            keyword: '4.  Долма - ',
                            main: ' блюдо, без которого не обходится ни одно армянское застолье. Это маленькие рулетики из фарша и риса, завернутые в виноградные листья. Существуют также  другие варианты начинки из различных круп и овощей без добавления мясного фарша. Долму очень вкусно есть с мацуном (местный кисломолочный напиток). ',
                        },
                        {
                            id: 816,
                            keyword: '5.  Хапама. Хэй джан, хапама -',
                            main: 'гласит известная армянская песня. А что же это за блюдо, которому посвящена целая песня? Хапама - это тыква, фаршированная рисом и сухофруктами, с добавлением меда и орехов. Хапама не является повседневным блюдом, как правило ее готовят на большие застолья: свадьбу, Новый год, а также Пасха.'
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 9,
        head: {
            image: nineth_post,
            theme: 'Вардавар',
            title: 'Серия мультика про Микки-Мауса, которая посвящена армянской культуре.',
            description: 'Что такое Вардавар?',
        },
        body: {
            title: 'Что такое Вардавар?',
            article: {
                title: 'Контент',
                topics: [
                    { id: 9000, text: 'смысл праздника' },
                    { id: 9001, text: 'традиции' },
                    { id: 9002, text: 'целебная сила' },
                ]
            },
            img: nineth_post,
            topics: [
                {
                    id: 90,
                    title: 'Это традиционный армянский праздник.',
                    descriptions: [
                        {
                            id: 901,
                            keyword: 'Вардавар в переводе с армянского',
                            main: ' языка означает «праздник роз» или по другой версии «вард» (уард) - означает вода и «вар» - мыть, поливать. Это один из главных праздников Армянской Апостольской Церкви, который отмечается в честь Преображения Господня через 14 недель после Пасхи. В 2024 году Вардавар выпадает на воскресенье 7 июля.',
                        }
                    ]
                },
                {
                    id: 91,
                    title: 'Традиция празднования',
                    descriptions: [
                        {
                            id: 910,
                            keyword: 'Если Вам посчастливиться оказаться',
                            main: ' в Армении именно в этот день, то будьте готовы выйти из дома и промокнуть с головы до ног. Во время этого праздника люди выходят на улицы с самого раннего утра и обливают друг-друга водой. В ход идет что угодно: ведра с водой, бутылки, водяные пистолеты и прочее.',
                        },
                        {
                            id: 911,
                            keyword: 'В Ереване самое массовое обливание',
                            main: ' обычно происходит на площади Республики, оттуда точно никому не удастся выйти сухим. В празднике принимают участие абсолютно все, независимо от пола, возраста или социального статуса. Особенно этот праздник приходится по душе детям. ',
                        },
                        {
                            id: 912,
                            keyword: 'Обижаться на то, что тебя облили водой,',
                            main: ' или выказывать недовольство нельзя, потому что считается что в этот день вода обладает особенной целебной силой. А учитывая жаркую июльскую погоду, участие в этом празднике и обливание водой поможет охладиться, и подарит незабываемые впечатления и воспоминания.',
                        },
                    ]
                },
            ]
        }
    },
    {
        id: 10,
        head: {
            image: tenth_post,
            theme: 'Арарат',
            title: 'Вид этой горы просто завораживает.',
            description: 'Если вы находитесь в Армении, Вы просто обязаны увидеть Арарат.',
        },
        body: {
            title: 'Что мы знаем об этой горе?',
            article: {
                title: 'Контент',
                topics: [
                    { id: 10000, text: 'смысл праздника' },
                    { id: 10001, text: 'традиции' },
                    { id: 10002, text: 'целебная сила' },
                ]
            },
            img: tenth_post,
            topics: [
                {
                    id: 100,
                    title: 'Загадочный Арарат',
                    descriptions: [
                        {
                            id: 1001,
                            keyword: 'Вид этой горы просто завораживает',
                            main: ' и никого не оставляет равнодушным. Среди местных ходит легенда, что Арарат показывается только тем людям, чьи помыслы чисты.',
                        }
                    ]
                },
                {
                    id: 101,
                    title: 'Что мы знаем об этой горе?',
                    descriptions: [
                        {
                            id: 1010,
                            keyword: 'Во-первых, ',
                            main: 'это та самая библейская священная гора, на которой высадился Ноев ковчег, спасаясь от Всемирного Потопа. Во-вторых,  Арарат это символ Еревана и всей Армении, даже не смотря на то, что фактически она находится на территории Турции. В-третьих, это остывший вулкан в центре армянского нагорья с двумя вершинами, высота которых составляет 3925 и 5165 метров.',
                        },
                        {
                            id: 1011,
                            keyword: 'Арарат один из древнейших ',
                            main: ' вулканов в мире и третья по величине гора в Западной Азии. В ясную погоду Арарат хорошо виден из многих точек Еревана. Но если вы хотите насладиться красотой Арарата в полной мере и сделать невероятно красивые фотографии, то вот подборка мест где виды будут особенно привлекательны',
                        },
                        {
                            id: 1012,
                            keyword: '1. Монастырь Хор-Вирап',
                            main: ' (в переводе с армянского «глубокая темница») - древний армянский монастырь, который находится в близи города Арташат. Этот монастырь известен тем, что на его территории находится темница, в которой когда то был заточен Григорий Просветитель. Географически монастырь расположен так, что является самой близкой точкой к горе Арарат и поэтому именно там открывается самый лучший вид.',
                        },
                        {
                            id: 1013,
                            keyword: '2. Каскад.',
                            main: ' В самом сердце Еревана располагается уникальное архитектурное сооружение, которое представляет из себя каскад из пяти открытых террас (в настоящее время имеется недостроенная часть). Если подняться вверх по этой лестнице и далее пройти вверх по склону, то вы окажетесь на верхней площадке каскада. Там располагается 50-ти метровая стела, установленная в честь 50-ти летия Советской Армении, а также смотровая площадка. Здесь можно насладиться неповторимым видом Еревана, над которым возвышается величественный Арарат. Для удобства рекомендуем начать посещение с верхней точки и далее постепенно спускаться вниз. '
                        },
                        {
                            id: 1014,
                            keyword: '3. Парк Ахтанак',
                            main: ' (парк Победы) - парк посвящен победе СССР во Второй Мировой войне. Главная достопримечательность парка величественный монумент Мать Армения, высота которого  вместе с постаментом достигает 54 м. Внутри постамента располагается музей истории войны, а рядом на гранитной площадке перед статуей горит вечный огонь. На территории парка также имеются прогулочные аллеи, пруд с очень живописным мостиком, множество кафе, а также масса аттракционов, в том числе колесо обозрения. Здесь также есть отличная смотровая площадка с которой можно любоваться живописными видами Еревана и конечно  же с нее виден Арарат. Стоит иметь в виду, что виден Арарат только в ясную погоду, поэтому если Вам повезло с погодой, то смело отправляйтесь в эти места и наслаждайтесь.'
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 11,
        head: {
            image: eleventh_post,
            theme: 'Детские мелочи',
            title: 'Где купить подгузники в Армении?',
            description: 'Самый подробный гайд по покупке столь незаменимого детского атрибута.',
        },
        body: {
            title: 'Где купить подгузники в Армении?',
            article: {
                title: 'Контент',
                topics: [
                    { id: 11000, text: 'подробный гайд' },
                    { id: 11001, text: 'вариация цен' },
                    { id: 11002, text: 'маркетплейсы' },
                ]
            },
            img: eleventh_post,
            topics: [
                {
                    id: 110,
                    title: 'Маленький ньюанс',
                    descriptions: [
                        {
                            id: 1101,
                            keyword: 'Кажется, что эта тема',
                            main: 'странная и ответ на этот вопрос очевиден,но не тут то было. В Армении это действительно вызывает трудности у родителей.',
                        }
                    ]
                },
                {
                    id: 111,
                    title: 'Сегодня поговорим о том где купить, какие бренды есть и самое главное сколько это стоит?',
                    descriptions: [
                        {
                            id: 1110,
                            keyword: 'Самый подробный гайд',
                            main: 'по покупке столь незаменимого детского атрибута. Если вы жили или живете в России и вам кажется, что подгузники стоят неоправданно дорого, то это вы просто еще не были в Армении, и не покупали подгузники здесь.',
                        },
                        {
                            id: 1111,
                            keyword: 'Во-первых, ',
                            main: ' если вы привыкли пользоваться не просто подгузниками, а трусиками-подгузниками, то первое с чем вы столкнетесь это то, что купить их намного сложнее, их практически нигде нет. В супермаркете, причем далеко не в каждом, если повезет, вы сможете купить только подгузники. А если Вам нужны именно трусики-подгузники, то они представлены в основном в аптеках, что крайне необычно для российского потребителя. Что касается брендов, выбирать особо не приходится:  всем известные pampers, libero и huggies, из японских - merries, а также реже встречаются малоизвестные в России бренды других стран.',
                        },
                        {
                            id: 1112,
                            keyword: 'Но самое интересное это цены,',
                            main: ' к примеру пачка трусиков бренда merries размера xl 38 штук обойдется вам примерно в 9000-11000 драм. В рублях это на минуточку около 2000-2500 рублей.',
                        },
                        {
                            id: 1113,
                            keyword: 'Если Вас не устраивает аптечный ассортимент,',
                            main: ' то вы можете воспользоваться различными сайтами, которые занимаются онлайн продажей детских товаров. Например есть сайт ',
                            link: 'kidsclub.am',
                            href: 'kidsclub.am',
                            end: ', где можно купить детские товары, в том числе и подгузники. В категории подгузники-трусики там  представлены бренды lovular, merries и Joonies. Возьмем к примеру трусики Joonies comfort 6-11 кг 54 штуки, их предлагают купить по цене 11900 драм (в рублях примерно 2800). На сайте российского магазина Детский Мир такие же трусики Joonies comfort в таком же размере и таком же количестве можно приобрести за 999 рублей, а трусики merries xl в количестве 38 штук - за 1459 рублей. Разница в ценах как говорится на лицо.'
                        },
                        {
                            id: 1114,
                            keyword: 'Ну а теперь обратимся о всеми любимому Wildberries. ',
                            main: ' ц предлагает нам приобрести их за 1825 рублей. Но если указать в качестве желаемого пункта выдачи  любой российский адрес, то мы увидим, что эти же подгузники будут стоить уже 1200 рублей. Наценка конечно не маленькая, и скорее всего это обусловлено транспортными издержками, а возможно и какими-то таможенными пошлинами.'
                        },
                         {
                            id: 1115,
                            keyword: 'Подводя итог ко всему сказанному,',
                            main: 'можно сделать один вывод - подгузники в Армении это дорого. А самый лучший способ сэкономить на их покупке, это как можно скорее приучить ребёнка к горшку и вообще от них отказаться. А если вы планируете путешествие с ребенком В Армению, то стоит подумать над тем, что возможно гораздо проще и выгоднее будет взять все необходимые средства гигиены с собой.'
                         }
                    ]
                },
            ]
        }
    },
    {
        id: 12,
        head: {
            image: twelfth_post,
            theme: 'Шоппинг в Армении.',
            title: 'Какие товары и каких брендов можно здесь купить?',
            description: 'Сегодня рассмотрим покупку одежды популярных брендов.',
        },
        body: {
            title: 'Шоппинг в Армении',
            article: {
                title: 'Контент',
                topics: [
                    { id: 12000, text: 'для взрослых' },
                    { id: 12001, text: 'для детей' },
                    { id: 12002, text: 'итог по покупкам' },
                ]
            },
            img: twelfth_post,
            topics: [
                {
                    id: 120,
                    title: 'Покупка различных товаров в Армении.',
                    descriptions: [
                        {
                            id: 1201,
                            keyword: 'Люди переехавшие из России ',
                            main: 'в Армению на пмж, а также просто туристы интересуются вопросом покупок различных товаров в Армении. Какие товары и каких брендов можно здесь купить, а также где это будет выгоднее в России или в Армении.',
                        }
                    ]
                },
                {
                    id: 121,
                    title: 'Сегодня рассмотрим покупку одежды популярных брендов.',
                    descriptions: [
                        {
                            id: 1210,
                            keyword: '1. Одежда для взрослых.',
                            main: 'Как известно в России ушли с рынка большинство популярных и любимых многими брендов масс маркета такие как: Zara, H&M, Bershka, Mango и тд. В Армении представлены некоторые из них, но стоит отметить, что цены на одежду этих брендов, особенно с учетом конвертации рублей в местную валюту - драмы, кусаются и очень ощутимо. Конечно всегда можно подобрать что-то из распродажи старой коллекции по очень приятным и выгодным ценам, но это скорее исключение. Поэтому нужно быть готовым к тому, что придется потратить значительно больше денег, чем когда эти же бренды были официально представлены в России. Российские  бренды одежды тоже стоят значительно дороже. Например одежду всем известной марки  Ostin будет намного выгоднее купить непосредственно в России.',
                        },
                        {
                            id: 1211,
                            keyword: '2. Детская одежда. ',
                            main: ' Для детской одежды также справедливо все те же условия, что и для взрослой. В торговых центрах Еревана представлены много замечательных международных брендов, такие как Next, детская линейка united collors of benetton, zippy, ovs kids и т.д. Цены в этих магазинах также нельзя назвать бюджетными. Здесь есть и российские бренды детской одежды, например Play today, Crockid и Acoola. Одежда Play today в России представлена как в отдельных официальных магазинах бренда, так и часто представлена в сети магазинов детский мир и является довольно бюджетной маркой. В Армении же магазин Play today не является таким дешевым.',
                        },
                        {
                            id: 1212,
                            keyword: 'Из всего вышесказанного можно сделать вывод,',
                            main: ' что Армения не является самой удачной страной для туристического шопинга. Многие местные жители наоборот предпочитают ездить на шопинг в Москву, так как даже не смотря на ушедшие популярные бренды, богатство ассортимента и ценовая политика остается все равно более привлекательной в России. Так что ехать в Армению целенаправленно закупить модной одежды по выгодным ценам явно не стоит. А вот приехать в Армению и посмотреть на невероятную красоту природы, проникнуться гостеприимством и дружелюбием местных жителей, а также насладиться прекрасной армянской кухней, непременно нужно каждому!',
                        },
                    ]
                },
            ]
        }
    },
]
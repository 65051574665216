import React from "react";
import { useParams, Link } from "react-router-dom";
import { posts } from "../../shared/posts";
const BlogItem = () => {
  const { id } = useParams();
  const {head, body } = posts.find((el) => el.id === parseInt(id));
  const { title, article, img, topics } = body;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="post-container">
      <p className="post-container_route">
        <Link to="/blog">блог</Link> &gt; {head.theme}
      </p>
      <h3 className="post-container_title">{title}</h3>
      <div className="post-container_wrapper">
        <article className="post-container_wrapper-article">
          <h2>{article.title}:</h2>
          <ul>
         {article.topics.map(el => (
          <li key={el.id}>{el.text}</li>
         ))}
          </ul>
        </article>
        <div className="post-container_wrapper-description">
          <div className="post-container_wrapper-description_image">
            <img src={img} alt="" />
          </div>
          {topics.map((el) => (
            <div
              className="post-container_wrapper-description_topic"
              key={el.id}
            >
              <h2>{el.title}</h2>
              {el.descriptions.map((item) => (
                 <div key={item.id}>
                   <p>
                    <span>{item?.keyword} </span>
                    {item.main}
                    <a target="_blank" href={item?.href}>{item?.link}</a>
                    {item?.end}
                  </p>
                 </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogItem;

import React from "react";
import { classNames } from "../shared/functions";
import { useNavigate } from "react-router-dom";

const Form = (props) => {
  const navigate = useNavigate()
  const connection = [
    { id: 0, name: "Telegram" },
    { id: 1, name: "What's up" },
    { id: 2, name: "Viber" },
    { id: 3, name: "По номеру" },
  ];
  const [formData, setFormData] = React.useState({
    name: "",
    birth: "",
    email: "",
    phone: "",
  });
  const [connectOptions, setConnectOptions] = React.useState([
    { state: "" },
    { state: "" },
    { state: "" },
    { state: "" },
  ]);
  const handleOption = (state, el) => {
    if (state) {
      setConnectOptions([
        ...connectOptions,
        connectOptions[el.id].state = el.name
      ])
    } else {
      setConnectOptions([
        ...connectOptions,
        connectOptions[el.id].state = ''
      ])
    }
  };
  const handlerFormSumbit = async (e) => {
    const opt = connectOptions.map(el => (
      el.state
    )).join(' ')
    try {
      const chatId = "699873191";
      const message = `Имя: ${formData.name}; Дата рождения: ${formData.birth}; Email: ${formData.email}, Номер телефона: ${formData.phone}. Вид связи: ${opt};`;
      const myToken = "6767376629:AAHLHvcmZ9H2UEamq7i_iWBKeIj_1Gvi9Aw";
      const response = await fetch(
        `https://api.telegram.org/bot${myToken}/sendMessage?chat_id=${chatId}&text=${message}`
      );
      if (response.ok) {
        e.preventDefault();
        navigate('/riding')
        alert('Заявка отправлена')
     
      }
    } catch (error) {
      console.error("something went wrong");
    }
  };
  return (
    <div id="form" className={classNames("form--container", props.classname)}>
      <div className="form--container_wrapper">
        <form
          onSubmit={handlerFormSumbit}
          action=""
          method="get"
          className="form--container_wrapper-form"
        >
          <h3>Оставить заявку</h3>
          <div className="form-example">
            <input
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              value={formData.name}
              type="text"
              placeholder="Имя и фамилия"
              name="name"
              id="name"
              required
            />
          </div>
          <div className="form-example">
            <input
              onChange={(e) =>
                setFormData({ ...formData, birth: e.target.value })
              }
              value={formData.birth}
              type="text"
              placeholder="Дата рождения"
              name="birth"
              id="birth"
              required
            />
          </div>
          <div className="form-example">
            <input
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              value={formData.email}
              type="email"
              placeholder="Email"
              name="email"
              id="email"
              required
            />
          </div>
          <div className="form-example">
            <input
              required
              type="number"
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
              value={formData.text}
              placeholder="Номер телефона"
            />
          </div>
          <div className="typeof--connection">
            <p>Предпочтительный способ связи:</p>
            <div className="typesof">
              {connection.map((el) => (
                <div key={el.id} className="type_item">
                  <input
                    type="checkbox"
                    onChange={(e) => handleOption(e.target.checked, el)}
                  />
                  <p>{el.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="form-example">
            <input
              className="submit"
              type="submit"
              value="Оставить заявку"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;

import React, { useState, useEffect, useRef } from "react";
import { rightArr, leftArr } from "../../assets/layouts/icons";
import { useSelector, useDispatch } from "react-redux";
import { formSliceData } from "../../Redux/slices/formSlice";
import { addOptionPrice, selectedCar, addDiscount, addStartOprionPrice } from "../../Redux/slices/carSlice";
import { getCurrentCar } from "../../shared/functions";
import { createFinalPrice } from "../../Redux/slices/formSlice";
import { addOption } from "../../Redux/slices/carSlice";
import CarParams from "./CarParams";
import { Link, useParams } from "react-router-dom";
import Constructor from "../Constructor";
import { simpleEncrypt } from "../../shared/functions";
import { encryptPromocodes } from "../../shared/constants";
const CarItem = () => {
  const decodedKey = process.env.REACT_APP_SECRET_KEY;
  const { model } = useParams();
  const { person } = useSelector(formSliceData);
  const car = getCurrentCar(model);
  const { selectedOptions, optionPrise } = useSelector(selectedCar);
  const [totalPrice, setTotalPrice] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(car.src);
  const [options, setOptions] = useState(car.checkboxes);
  const [optionsPrice, setOptionPrice] = useState(0);
  const [starOptionPrice, setStarOptionPrice] = useState(0);
  const [discount, setDiscount] = useState("");
  const [albumPhoto, setAlbumPhoto] = useState(0);
  const album = car.album;
  const inputRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handlePhoto = (event, index) => {
    const photoSrc = event.target.currentSrc;
    setSelectedPhoto(photoSrc);
    setAlbumPhoto(index);
  };

  const handleAlbumPrev = () => {
    setAlbumPhoto((prevAlbumPhoto) => {
      const newAlbumPhoto =
        prevAlbumPhoto === 0 ? album.length - 1 : prevAlbumPhoto - 1;
      setSelectedPhoto(album[newAlbumPhoto]);
      return newAlbumPhoto;
    });
  };
  const handleAlbumNext = () => {
    setAlbumPhoto((prevAlbumPhoto) => {
      const newAlbumPhoto =
        prevAlbumPhoto === album.length - 1 ? 0 : prevAlbumPhoto + 1;
      setSelectedPhoto(album[newAlbumPhoto]);
      return newAlbumPhoto;
    });
  };
  const handleDiscountWork = (e) => {
    let discoundCode = e.target.value.toLocaleUpperCase()
    setDiscount(discoundCode)
    dispatch(addDiscount(discoundCode))
  };
  const handlePriceChange = (changedOption) => {
    if (changedOption.star) {
      if (!changedOption.selected) {
        let finalprice = starOptionPrice + changedOption.price
        setStarOptionPrice(parseInt(finalprice));
        dispatch(addStartOprionPrice(finalprice))
      } else {
        let finalprice =starOptionPrice - changedOption.price;
        setStarOptionPrice(parseInt(finalprice));
        dispatch(addStartOprionPrice(finalprice))
      }
    } else {
      if (!changedOption.selected) {
        let finalprice = optionsPrice + changedOption.price
        setOptionPrice(parseInt(finalprice))
        dispatch(addOptionPrice(finalprice))
      } else {
        let finalprice = optionsPrice - changedOption.price
        setOptionPrice(parseInt(finalprice));
        dispatch(addOptionPrice(finalprice))
      }
    }
    const updatedOptions = options.map((option) => {
      if (option.id === changedOption.id) {
        // Если это измененный вариант, инвертируем его состояние selected
        return { ...option, selected: !option.selected };
      }
      return option;
    });
    setOptions(updatedOptions);
    dispatch(addOption(changedOption));
  };
  const calculateDays = () => {
    const { placeFrom, placeTo, dateFrom, timeFrom, dateTo, timeTo } = person;
    const carPrice = car.price;
    const promoDiscount =
      encryptPromocodes[simpleEncrypt(discount, decodedKey)]
    if (discount.trim() !== "") {
      if (promoDiscount) {
        inputRef.current.style.border = "1px solid green";
      } else {
        inputRef.current.style.border = "1px solid red";
      }
    }
    let extraDiscount = promoDiscount ? 1 - promoDiscount / 100 : 1;
    const startDate = new Date(`${dateFrom}T${timeFrom}:00`);
    const endDate = new Date(`${dateTo}T${timeTo}:00`);
    const timeDifference = endDate - startDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24)
    const oprionPrice =
      daysDifference < 6
        ? optionsPrice + starOptionPrice * daysDifference
        : optionsPrice + starOptionPrice * 5;
    if (daysDifference < 0) {
      setTotalPrice("неверная дата");
    } else {
      let firstTax = 0,
        finishPrice = 0,
        lastTax = 0;
      if (placeFrom === "г. Ереван, ул. Фанаржян, д. 35.") {
        firstTax = 0;
      } else {
        firstTax = 4000;
      }
      if (placeTo === "г. Ереван, ул. Фанаржян, д. 35.") {
        lastTax = 0;
      } else {
        lastTax = 4000;
      }
      let taxes = firstTax + lastTax;
      if (daysDifference > 3 && daysDifference <= 7) {
        finishPrice =
          Math.floor(
            ((carPrice * 0.95 * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      } else if (daysDifference > 7 && daysDifference <= 14) {
        finishPrice =
          Math.floor(
            ((carPrice * 0.9 * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      } else if (daysDifference > 14 && daysDifference <= 30) {
        finishPrice =
          Math.floor(
            ((carPrice * 0.85 * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      } else if (daysDifference > 30) {
        finishPrice =
          Math.floor(
            ((carPrice * 0.8 * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      } else {
        finishPrice =
          Math.floor(
            ((carPrice * daysDifference + taxes) * extraDiscount +
              oprionPrice) /
              100
          ) * 100;
      }
      setTotalPrice(finishPrice);
      dispatch(createFinalPrice(finishPrice));
    }
  };
  return (
    <div className="modal">
      <div className="modal--container">
        <div className="modal--container-car">
          <div className="main-car">
            <img src={selectedPhoto} alt="" />
            <div className="main-car__buttons">
              <div className="main-car__buttons-left" onClick={handleAlbumPrev}>
                <img src={leftArr} alt="arr" />
              </div>
              <div
                className="main-car__buttons-right"
                onClick={handleAlbumNext}
              >
                <img src={rightArr} alt="arr" />
              </div>
            </div>
          </div>
          <div className="album">
            {album.map((el, index) => (
              <img
                src={el}
                alt="car"
                key={index}
                onClick={(event) => handlePhoto(event, index)}
              />
            ))}
          </div>
        </div>
        <div className="modal--container-settings">
          <h3>{car.discr}</h3>
          <CarParams props={car} />
          <p className="discr">{car.title}</p>
          <p className="discr">{car.about}</p>
        </div>
      </div>
      <div className="modal--container-constructor">
        <Constructor classname="modal--form active" car={car.discr} totalCarPrice={calculateDays} />
        <div className="checkboxes--container">
          <div className="star--conditions">
            <h3>
              5. Выберите дополнительные&nbsp;
              <Link target="_blank" to="/conditions">
                услуги
              </Link>
            </h3>
          </div>
          {options.map((el, index) => (
            <div className="checkboxes--container-item" key={index}>
              <input
                type="checkbox"
                name={car.id}
                checked={el.selected}
                onChange={() => handlePriceChange(el)}
              />
              <div className="checkboxes--container-item_text">
                <p>{el.name}</p>
              </div>
            </div>
          ))}
          <div className="checkboxes--container_terms">
            <div className="discount_container">
              <div>
                <label htmlFor="discount">Введите купон:</label>
                <p>* Купон не применяется на доп. услуги</p>
              </div>
              <input
                ref={inputRef}
                type="text"
                id="discount"
                name="discount"
                placeholder="PROMOCODE"
                // maxLength="11"
                value={discount}
                onChange={(e) => handleDiscountWork(e)}
              />
            </div>
            <div className="price_container">
              <p onClick={() => calculateDays()}>Рассчитать итог</p>
              <p>{totalPrice > 0 ? `${totalPrice}֏` : ""}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarItem;

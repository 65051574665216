import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  car: {},
  selectedOptions: [],
  carPrice: 0,
  optionPrice: 0,
  starOptionPrice: 0,
  discountCode: ''
}

const carSlice = createSlice({
  name: 'car',
  initialState,
  reducers: {
    addCar(state, action) {
      state.car = action.payload
      state.carPrice = action.payload.price
    },
    addOption(state, action) {
      const { id, price, star } = action.payload;
      const existingOptionIndex = state.selectedOptions.findIndex((option) => option.id === id)
      if (existingOptionIndex !== -1) {
        state.selectedOptions.splice(existingOptionIndex, 1);
      } else {
        state.selectedOptions.push(action.payload);

      }
    },
    changePrice(state, action) {
      state.optionPrice += action.payload
    },
    addOptionPrice(state, action) {
      state.optionPrice = action.payload

    },
    addStartOprionPrice(state, action) {
      state.starOptionPrice = action.payload

    },
    toggleSelected(state, action) {
      const { id, selected } = action.payload;
      const option = state.car.checkboxes.find((opt) => opt.id === id);
      if (option) {
        option.selected = selected;
      }
    },
    removeOption(state, action) {
      const { id } = action.payload
      state.options = state.options.filter(el => (el.id !== id))
      const option = state.car.checkboxes.find((opt) => opt.id === id);
      if (option) {
        option.selected = false;
      }
    },
    addDiscount(state, action) {
      state.discountCode = action.payload
    },
    defaultState(state) {
      state.car = {}
      state.options = []
      state.optionPrice = 0
      state.carPrice = 0
    }
  }
})

export const selectedCar = (state) => state.car

export const { addDiscount, addCar, addOption, changePrice, toggleSelected, removeOption, defaultState, addOptionPrice, addStartOprionPrice } = carSlice.actions

export default carSlice.reducer
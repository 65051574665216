import { carsPull } from './constants'
export function classNames(cls, mods = {}, additional = []) {
  const classes = [cls, ...additional.filter(Boolean)];
  var newClass = ''
  for (const value of Object.values(mods)) {
    if (value) {
      newClass += value
    }
  }
  classes.push(newClass)
  return classes.join(' ');
}

export const getCurrentCar = (model) => {
  return carsPull.find(el => el.link === model)
}

export const getHours = () => {
  const hoursArray = [];

  for (var i = 0; i < 24; i++) {
    var hour = ('0' + i).slice(-2);
    hoursArray.push({ id: i + 1, value: hour });
  }
  return hoursArray

}

export const simpleEncrypt = (text, key) => {
      let result = '';
      for (let i = 0; i < text.length; i++) {
        const char = text.charCodeAt(i);
        const shift = key.charCodeAt(i % key.length);
        result += String.fromCharCode(char + shift);
      }
      return result;
}
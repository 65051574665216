import React from 'react'
import BlogPost from '../components/Blog/BlogPost'
import { posts } from '../shared/posts'
const Blog = () => {
  return (
   <section className='blog-section'>
    <h2 className='blog-section_title'>Блог</h2>
     <div className='blog-section_items'>
     {posts.map(el => (
         <BlogPost key={el.id} data = {el}/>
       ))}
     </div>
   </section>
  )
}

export default Blog